<template>
  <v-card>
    <v-card-subtitle v-if="!editingInfo">
      <v-row class="pl-4">
        Terms
        <base-button
          color="info"
          icon-bool
          x-small
          @clicked="editingInfo = true"
          v-if="$store.getters.can('edit-yards')"
          icon="mdi-pencil"
        />
      </v-row>
    </v-card-subtitle>
    <div
      class="ml-4 mt-n4"
      v-if="Object.keys(brokerTerms).length !== 0 && !editingInfo"
    >
      <v-row>
        <card-list-item
          v-if="brokerTerms.minimum_volume_type === 'UNT'"
          title="Minimum Units Required"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.minimum_volume_units }}
        </card-list-item>
        <card-list-item
          v-if="brokerTerms.minimum_volume_type === 'LBS'"
          title="Minimum Units Pounds"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.minimum_volume_lbs }} lbs
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Pt Rate"
        >
          {{ brokerTerms.pt_rate | filterZero }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Pd Rate"
        >
          {{ brokerTerms.pd_rate | filterZero }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Rh Rate"
        >
          {{ brokerTerms.rh_rate | filterZero }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          title="Treatment Charge"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.treatment_charge | appendPerLb | dollarSign | filterZero }}
        </card-list-item>
        <card-list-item
          title="Handling Fee"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.handling_fee | dollarSign | filterZero }}
        </card-list-item>
        <card-list-item
          title="Finance Charge %"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.finance_charge_percent }}
        </card-list-item>
        <card-list-item
          title="Payout Type"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.ounce_payout_type }}
        </card-list-item>
        <card-list-item
          title="Freight Responsibility"
          :breakpoint-col-val="6"
          :other-col-val="4"
        >
          {{ brokerTerms.freight_responsibility }}
        </card-list-item>
      </v-row>

      <v-row>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Pt Refining Charge"
        >
          ${{ brokerTerms.refining_charge_pt | filterZero }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Pd Refining Charge"
        >
          ${{ brokerTerms.refining_charge_pd | filterZero }}
        </card-list-item>
        <card-list-item
          breakpoint="mdAndDown"
          :breakpoint-col-val="6"
          :other-col-val="4"
          title="Rh Refining Charge"
        >
          ${{ brokerTerms.refining_charge_rh | filterZero }}
        </card-list-item>
      </v-row>
      <card-list-item
        no-cols
        title="Additional Terms"
      >
        {{ brokerTerms.additional_terms | filterNA }}
      </card-list-item>
      <card-list-item
        no-cols
        title="Description"
      >
        {{ brokerTerms.description | filterNA }}
      </card-list-item>
      <v-list-item v-if="brokerTerms">
        <v-list-item-content>
          <v-list-item-subtitle>
            Brokerage Files/Terms
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions
        v-if="brokerTerms"
        no-cols
      >
        <user-upload-list
          :file-links="fileLinks"
        />
      </v-card-actions>
      <v-card-actions
        v-if="brokerTerms"
        no-cols
      >
        <user-upload-dialog :file-links="fileLinks">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              @click="on"
            >
              Attach Brokerage Files/Terms
            </v-btn>
          </template>
        </user-upload-dialog>
      </v-card-actions>
    </div>
    <card-list-item
      :other-col-val="12"
      v-else-if="!editingInfo"
      title="This yard does not have terms. Click pencil to add."
    />
    <write-broker-terms
      v-else
      :editing="true"
      :broker-terms="brokerTerms"
      @addTerms="addTerms"
      @updateTerms="updateTerms"
      @cancel="editingInfo = false"
    />
  </v-card>
</template>

<script>
import WriteBrokerTerms from '../Write/WriteBrokerTerms'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import fileLinks from '@/helpers/fileLinks'
import UserUploadList from '@/base/components/UserUploadList'

export default {
  name: 'BrokerTerms',
  props: {
    brokerTerms: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    UserUploadDialog,
    WriteBrokerTerms,
    UserUploadList
  },
  data: () => ({
    editingInfo: false
  }),
  computed: {
    fileLinks () {
      return fileLinks.brokerageTermsFileLinks(this.brokerTerms)
    }
  },
  methods: {
    addTerms (params) {
      this.editingInfo = false
      this.$emit('addTerms', params)
    },
    updateTerms (params) {
      this.editingInfo = false
      this.$emit('updateTerms', params)
    }
  }
}
</script>
